import { FC } from 'react';

import { Typography } from '@/shared/components/ui/Typography';
import { classnames } from '@/shared/utils/classnames.ts';

import Loader from '@/assets/icons/section-loader.svg?react';
import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  fullHeight?: boolean;
  loaderText?: JSX.Element | string;
  sectionLoaderClassName?: string;
  loaderTextClassName?: string;
};

export const SectionLoader: FC<Props> = ({
  className,
  fullHeight,
  loaderText,
  loaderTextClassName,
  sectionLoaderClassName,
}) => {
  return (
    <div
      className={classnames(
        'flex w-full flex-col content-center items-center justify-center gap-y-2',
        `${fullHeight && 'h-[100dvh]'}`,
        className,
      )}
    >
      <Loader
        className={classnames('h-16 w-16 animate-spin text-blue-500', sectionLoaderClassName)}
      />

      {loaderText && (
        <Typography
          className={classnames('text-lg font-medium text-slate-950', loaderTextClassName)}
        >
          {loaderText}
        </Typography>
      )}
    </div>
  );
};
